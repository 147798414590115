body {
  text-align: center;
  font-size: 1em;
  font-family: verdana;
  margin: 0;
}

h1 {
  text-align: center;
  font-size: 2em;
  padding: 10px 0;
  margin: 0 0 0 0;
  color: #333
}

h2 {
  text-align: center;
  font-size: 0.8em;
  padding: 0 0;
  margin: 0 0 20px 0;
  color: #333
}

h3 {
  text-align: center;
  font-size: 0.8em;
  padding: 0 0;
  margin: 30px 0 30px 0;
  color: #333
}

.letter-lines {
  display: flex;
  gap: 8px;
  text-align: center;
  justify-content: center;
}

.letter-box {
  font-size: 24px;
  border-bottom: 2px solid black;
  width: 20px;
  text-align: center;
}

.keypad {
  max-width: 500px;
  margin: 20px auto;
}

.keypad-row {
  display: flex; /* Aligns keys in a row */
  justify-content: center; /* Center the keys */
  margin-bottom: 10px; /* Space between rows */
}

.key {
  margin: 5px;
  width: 40px;
  height: 50px;
  border-radius: 6px;
  line-height: 50px;
  text-align: center; /* Center text */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.help-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 24px; /* Adjust size as needed */
  cursor: pointer;
  z-index: 1001; /* Ensure it sits above the popup */
}

.button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove default border */
  color: white; /* White text color */
  padding: 12px 20px; /* Padding for top/bottom and left/right */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Allows to set width and height */
  font-size: 16px; /* Font size */
  margin: 4px 2px; /* Margin around buttons */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}